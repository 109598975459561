import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/abdullatieffathoni/Code/javascript/tds-web/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const ImageCard = makeShortcode("ImageCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p><inlineCode parentName="p">{`Accordion`}</inlineCode>{` adalah sebuah daftar header vertikal yang dapat diklik memungkinkan user untuk memperlihatkan dan menyembunyikan konten terkait.`}</p>
    </PageDescription>
    <h2>{`Example`}</h2>
    <h3>{`With Container`}</h3>
    <Row className="image-card-group" mdxType="Row">
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ImageCard title="Collapse" titleColor="dark" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "456px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB3wAUAH//xAAUEAEAAAAAAAAAAAAAAAAAAAAw/9oACAEBAAEFAh//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAUEAEAAAAAAAAAAAAAAAAAAAAw/9oACAEBAAY/Ah//xAAXEAADAQAAAAAAAAAAAAAAAAABECBB/9oACAEBAAE/IXor/9oADAMBAAIAAwAAABDTwAD/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/EB//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/EB//xAAYEAEBAAMAAAAAAAAAAAAAAAABERAgQf/aAAgBAQABPxClmUVuG3//2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Square",
              "title": "Square",
              "src": "/static/c13e0eee01b72252783ad8237d8c28d5/068e4/container-collapse.jpg",
              "srcSet": ["/static/c13e0eee01b72252783ad8237d8c28d5/69549/container-collapse.jpg 288w", "/static/c13e0eee01b72252783ad8237d8c28d5/068e4/container-collapse.jpg 456w"],
              "sizes": "(max-width: 456px) 100vw, 456px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ImageCard title="Expanded" titleColor="dark" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "456px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAECBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAe9cw2AAD//EABgQAAIDAAAAAAAAAAAAAAAAAAABECAh/9oACAEBAAEFApZtf//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EABQQAQAAAAAAAAAAAAAAAAAAADD/2gAIAQEABj8CH//EABkQAQACAwAAAAAAAAAAAAAAABEAARAgUf/aAAgBAQABPyG7Mtoh3r//2gAMAwEAAgADAAAAENPPPP/EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8QH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8QH//EABoQAQABBQAAAAAAAAAAAAAAAAERABAgIUH/2gAIAQEAAT8QFkoD1bhSQaJNkMf/2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Square",
              "title": "Square",
              "src": "/static/64a8b1aebdb855bcece18ad87001c4fc/068e4/container-expanded.jpg",
              "srcSet": ["/static/64a8b1aebdb855bcece18ad87001c4fc/69549/container-expanded.jpg 288w", "/static/64a8b1aebdb855bcece18ad87001c4fc/068e4/container-expanded.jpg 456w"],
              "sizes": "(max-width: 456px) 100vw, 456px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
    </Row>
    <h3>{`Without Container`}</h3>
    <Row className="image-card-group" mdxType="Row">
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ImageCard title="Collapse" titleColor="dark" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "456px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB3wAUAH//xAAUEAEAAAAAAAAAAAAAAAAAAAAw/9oACAEBAAEFAh//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAUEAEAAAAAAAAAAAAAAAAAAAAw/9oACAEBAAY/Ah//xAAXEAADAQAAAAAAAAAAAAAAAAABECBB/9oACAEBAAE/IXor/9oADAMBAAIAAwAAABDTwAD/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/EB//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/EB//xAAZEAEBAAMBAAAAAAAAAAAAAAABEQAQQSD/2gAIAQEAAT8QpZtFbhc55//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Square",
              "title": "Square",
              "src": "/static/bb648d46e8845a8e20b4601211fedc5a/068e4/collapse.jpg",
              "srcSet": ["/static/bb648d46e8845a8e20b4601211fedc5a/69549/collapse.jpg 288w", "/static/bb648d46e8845a8e20b4601211fedc5a/068e4/collapse.jpg 456w"],
              "sizes": "(max-width: 456px) 100vw, 456px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ImageCard title="Expanded" titleColor="dark" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "456px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAECBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAe+zDYKAD//EABgQAAIDAAAAAAAAAAAAAAAAAAABECAh/9oACAEBAAEFApZtf//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EABQQAQAAAAAAAAAAAAAAAAAAADD/2gAIAQEABj8CH//EABkQAAIDAQAAAAAAAAAAAAAAAAERABAggf/aAAgBAQABPyGxJSnOf//aAAwDAQACAAMAAAAQ08AA/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPxAf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPxAf/8QAHBABAAEEAwAAAAAAAAAAAAAAAREAECAhMVFx/9oACAEBAAE/EFDlD27SIx3RN3DH/9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Square",
              "title": "Square",
              "src": "/static/e07c307bcd566b25715ff5353ea50010/068e4/expanded.jpg",
              "srcSet": ["/static/e07c307bcd566b25715ff5353ea50010/69549/expanded.jpg 288w", "/static/e07c307bcd566b25715ff5353ea50010/068e4/expanded.jpg 456w"],
              "sizes": "(max-width: 456px) 100vw, 456px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
    </Row>
    <h3>{`List`}</h3>
    <Row className="image-card-group" mdxType="Row">
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ImageCard title="Default" titleColor="dark" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "456px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB3rKUEBQf/8QAFhABAQEAAAAAAAAAAAAAAAAAARAg/9oACAEBAAEFAmuv/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPwEf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPwEf/8QAFBABAAAAAAAAAAAAAAAAAAAAMP/aAAgBAQAGPwIf/8QAGBAAAgMAAAAAAAAAAAAAAAAAARARIDH/2gAIAQEAAT8hKA8W/9oADAMBAAIAAwAAABDwDwD/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/EB//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/EB//xAAbEAEAAgIDAAAAAAAAAAAAAAABEBEAMUFRsf/aAAgBAQABPxBLDuffOIQdl4AFBUf/2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Square",
              "title": "Square",
              "src": "/static/ca047489f37d80dd69c09c0ad99d50b7/068e4/list.jpg",
              "srcSet": ["/static/ca047489f37d80dd69c09c0ad99d50b7/69549/list.jpg 288w", "/static/ca047489f37d80dd69c09c0ad99d50b7/068e4/list.jpg 456w"],
              "sizes": "(max-width: 456px) 100vw, 456px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ImageCard title="List Option" titleColor="dark" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "456px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB3rKUEBQf/8QAFhABAQEAAAAAAAAAAAAAAAAAARAg/9oACAEBAAEFAmuv/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPwEf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPwEf/8QAFBABAAAAAAAAAAAAAAAAAAAAMP/aAAgBAQAGPwIf/8QAGRABAAIDAAAAAAAAAAAAAAAAAQARECAx/9oACAEBAAE/IVRl0Q5r/9oADAMBAAIAAwAAABDADwD/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/EB//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/EB//xAAbEAEBAAMAAwAAAAAAAAAAAAABEQAQMSFRcf/aAAgBAQABPxCM+0MtKag+Oszn80g9LgAQ1//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Square",
              "title": "Square",
              "src": "/static/f3a2e83b3b3d551d1aae43d2f79c0053/068e4/list-option.jpg",
              "srcSet": ["/static/f3a2e83b3b3d551d1aae43d2f79c0053/69549/list-option.jpg 288w", "/static/f3a2e83b3b3d551d1aae43d2f79c0053/068e4/list-option.jpg 456w"],
              "sizes": "(max-width: 456px) 100vw, 456px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      